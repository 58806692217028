



























































































































import { Component, Vue } from 'vue-property-decorator';

import AllianazWithdrawalDistributeRetirementViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianaz-withdrawal-distribute-retirement-view-model';

@Component({
  name: 'AllianzWithdrawalInformation',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class AllianzWithdrawalDistributeRetirement extends Vue {
  withdrawal_distribute_retirement_view_model = Vue.observable(
    new AllianazWithdrawalDistributeRetirementViewModel(this),
  );
}
