import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import CreateTransactionCommand
  from '@/modules/my-investment/allianz/transaction/application/commands/create-transaction-command';

import SearchTransactionTypesQuery
  from '@/modules/my-investment/allianz/transaction-type/application/queries/search-transaction-types-query';

import SearchCurrencyTypesQuery
  from '@/modules/my-investment/allianz/currency-type/application/queries/search-currency-types-query';

import SearchTransactionStatusQuery
  from '@/modules/my-investment/allianz/transaction-status/application/queries/search-transaction-status-query';

import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';

import GetAllianzWithdrawalOperationDatesQuery
  from '@/modules/my-investment/allianz/allianz-operation-dates/application/queries/get-allianz-withdrawal-operation-dates-query';

import InvestorGoalScheduledDistributionWealthWithdrawalCommand
  from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/withdrawal/application/command/investor-goal-scheduled-distribution-wealth-withdrawal-command';

// Domain
import { CreateTransactionDto } from
  '@/modules/my-investment/allianz/transaction/domain/dtos/create-transaction-dto';
import {
  WithdrawalDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/withdrawal-dto';
import {
  InvestorGoalScheduledDistributionWealthWithdrawalDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/withdrawal/domain/dto/investor-goal-scheduled-distribution-wealth-withdrawal-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzWithdrawalConfirmViewModel {
  @Inject(TYPES.CREATE_ALLIANZ_TRANSACTIONS_COMMAND)
  private readonly create_transaction_command!: CreateTransactionCommand;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_ALLIANZ_WITHDRAWALS_OPERATION_DATES_QUERY)
  private readonly get_allianz_withdrawal_dates_query!: GetAllianzWithdrawalOperationDatesQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_STATUS_QUERY)
  private readonly search_transaction_status_query!: SearchTransactionStatusQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_TYPES_QUERY)
  private readonly search_transaction_types_query!: SearchTransactionTypesQuery;

  @Inject(TYPES.SEARCH_CURRENCY_TYPES_QUERY)
  private readonly search_currency_types_query!: SearchCurrencyTypesQuery;

  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_WITHDRAWAL_COMMAND)
  // eslint-disable-next-line max-len
  private readonly investor_goal_scheduled_distribution_wealth_withdrawal_command!: InvestorGoalScheduledDistributionWealthWithdrawalCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.withdrawals.withdrawal_confirm';

  private readonly view: Vue;

  private customer_id = '';

  private allianz_account_id = '';

  private transaction_status_id = '';

  private transaction_type_id = '';

  private currency_type_id = '';

  is_loading = false;

  policy_number = 0;

  policy_number_issued = '';

  accreditation_date = '';

  information = {
    source_account: {
      id: '',
      customer_id: '',
      allianz_account_id: '',
      transaction_id: '',
      request_number: '',
      product: '',
      policy_number: 0,
      client_code: '',
      client_name: '',
      activation_date: '',
      beginning_of_validity_date: '',
      agent_office: 0,
      agent_code: '',
      agent_name: '',
      promoter_code: '',
      promoter_name: '',
      grouper_code: '',
      grouper_name: '',
      advisor_code: '',
      advisor_name: '',
      item: '',
      found_code: '',
      found_name: '',
      amount_contributed: 0.00,
      withdrawal_amount: 0.00,
      charge_amount: 0.00,
      units: 0.00,
      units_value: 0.00,
      final_balance: 0.00,
      final_balance_mxn: 0.00,
      last_payment_date: '',
      updated_at: '',
      created_at: '',
    },
    available_balance: 0,
    destination_account: '',
    amount_to_withdraw: '',
  };

  payload_to_save_distribution: InvestorGoalScheduledDistributionWealthWithdrawalDto = {
    total_contribution: 0,
    unassigned_balance: 0,
    is_deposit: false,
    investor_goal_scheduled_distributions: [],
  }

  constructor(view: Vue) {
    this.view = view;
  }

  get formatted_source() {
    return `${this.information.source_account.found_name} - ${currencyFormat(this.information.available_balance)} MXN`;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setOperationDates = async (transaction_type_id: string) => {
    const op_dates = await this.get_allianz_withdrawal_dates_query.execute(transaction_type_id);

    const day = this.date_formatter.formatDate(op_dates.accreditation_operation_date, 'DD');
    const month = this.date_formatter.formatDate(op_dates.accreditation_operation_date, 'MMMM');
    const year = this.date_formatter.formatDate(op_dates.accreditation_operation_date, 'YYYY');
    this.accreditation_date = this.translate('date', { day, month, year });
  }

  confirmOperation = async (id: string) => {
    this.is_loading = true;
    try {
      const allianz_transaction: CreateTransactionDto = {
        id,
        customer_id: this.customer_id,
        allianz_account_id: this.allianz_account_id,
        policy_number: this.policy_number,
        transaction_status_id: this.transaction_status_id,
        transaction_type_id: this.transaction_type_id,
        currency_type_id: this.currency_type_id,
        amount: parseFloat(this.information.amount_to_withdraw.replace(/[^0-9.]/g, '')),
        source_fund_code: this.information.source_account.found_code,
        source_fund_name: this.information.source_account.found_name,
        destination_fund_code: null,
        destination_fund_name: null,
      };
      await this.create_transaction_command.execute(allianz_transaction);
      if (this.payload_to_save_distribution.total_contribution) {
        await this.saveDistributionAmongLinkedGoals();
      }
      const transaction_event = new CustomEvent('allianz.create.pending.transaction', { detail: { ...allianz_transaction } });
      window.dispatchEvent(transaction_event);
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('error_on_create_transaction'));
      return false;
    } finally {
      this.is_loading = false;
    }
  }

  saveDistributionAmongLinkedGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      await this.investor_goal_scheduled_distribution_wealth_withdrawal_command.execute(this.payload_to_save_distribution);
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.investor_goal_scheduled_distribution_wealth_withdrawal_command'));
    }
  }

  // eslint-disable-next-line max-len
  setPayloadToSaveDistribution = (payload: InvestorGoalScheduledDistributionWealthWithdrawalDto) => {
    this.payload_to_save_distribution = { ...payload };
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('confirmWithdrawal', this.accreditation_date);
  }

  setInformation = (information: WithdrawalDto) => {
    this.information = information;
  }

  initialize = async () => {
    this.is_loading = true;
    const transaction_types = await this.search_transaction_types_query.execute();
    const transaction_status = await this.search_transaction_status_query.execute();
    const currency_types = await this.search_currency_types_query.execute();
    const allianz_account = await this.get_allianz_account_query.execute(
      { customer_id: this.customer_id },
    );
    const deposit_type = transaction_types.find((transaction_type) => transaction_type.name === 'Withdrawal');
    if (deposit_type) {
      this.transaction_type_id = deposit_type.id;
    }
    const status_pending = transaction_status.find((status) => status.name === 'Pending');
    if (status_pending) {
      this.transaction_status_id = status_pending.id;
    }
    const currency_pesos = currency_types.find((currency_type) => currency_type.name === 'Mexican Peso');
    if (currency_pesos) {
      this.currency_type_id = currency_pesos.id;
    }
    await this.setOperationDates(this.transaction_type_id);
    this.customer_id = allianz_account.customer_id;
    this.allianz_account_id = allianz_account.id;
    this.policy_number = allianz_account.policy_number;
    this.policy_number_issued = allianz_account.policy_number_issued;
    this.is_loading = false;
  }
}
