




















































import { v4 } from 'uuid';

import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import AllianzWithdrawalViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-view-model';
import AllianzWithdrawalInformation
  from '@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalInformation.vue';
import AllianzWithdrawalConfirm
  from '@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalConfirm.vue';
import AllianzWithdrawalDistributeRetirement
  from './AllianzWithdrawalDistributeRetirement.vue';

// Domain
import {
  WithdrawalDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/withdrawal-dto';
import {
  InvestorGoalScheduledDistributionWealthWithdrawalDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/withdrawal/domain/dto/investor-goal-scheduled-distribution-wealth-withdrawal-dto';

@Component({
  name: 'AllianzWithdrawal',
  components: {
    AllianzWithdrawalSchedule: () => import('@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalSchedule.vue'),
    AllianzWithdrawalPendingRequests: () => import('@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalPendingRequests.vue'),
    AllianzWithdrawalInformation,
    AllianzWithdrawalDistributeRetirement,
    AllianzWithdrawalModerate: () => import('@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalModerate.vue'),
    AllianzWithdrawalConfirm,
    AllianzWithdrawalResult: () => import('@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalResult.vue'),
  },
})
export default class AllianzWithdrawal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  allianz_withdrawal_view_model = Vue.observable(
    new AllianzWithdrawalViewModel(),
  );

  $refs!: {
    allianz_withdrawal_information: Array<AllianzWithdrawalInformation>;
    allianz_withdrawal_confirm: Array<AllianzWithdrawalConfirm>;
    allianz_withdrawal_distribute_retirement: Array<AllianzWithdrawalDistributeRetirement>;
  }

  created() {
    this.allianz_withdrawal_view_model.initialize();
  }

  endProcess() {
    this.$refs.allianz_withdrawal_information[0].withdrawal_information_view_model.reset();
    this.allianz_withdrawal_view_model.reset();
    this.synced_is_open = false;
  }

  confirmSchedule() {
    this.$refs.allianz_withdrawal_information[0].withdrawal_information_view_model.loadPosition();
    this.allianz_withdrawal_view_model.nextStep();
  }

  updateInvestmentProductFundTypeId(investment_product_fund_type_id: string) {
    this.allianz_withdrawal_view_model.setInvestmentProductFundTypeId(
      investment_product_fund_type_id,
    );
  }

  updateInformation(withdrawal_information: WithdrawalDto) {
    this.$refs.allianz_withdrawal_confirm[0].withdrawal_confirm_view_model
      .setInformation(withdrawal_information);
    if (this.allianz_withdrawal_view_model.is_any_goal_active) {
      const retirement_amount = parseFloat(withdrawal_information.amount_to_withdraw.replace(/[^0-9.]/g, ''));
      this.$refs.allianz_withdrawal_distribute_retirement[0]
        .withdrawal_distribute_retirement_view_model
        .setRetirementAmount(
          retirement_amount, withdrawal_information.investment_product_fund_type_id,
        );
    }
    this.allianz_withdrawal_view_model.nextStep();
  }

  // eslint-disable-next-line max-len
  showModalFlexibleDepositConfirmation = (payload: InvestorGoalScheduledDistributionWealthWithdrawalDto) => {
    this.$refs.allianz_withdrawal_confirm[0]
      .withdrawal_confirm_view_model.setPayloadToSaveDistribution(payload);
    this.allianz_withdrawal_view_model.nextStep();
  }

  async confirmWithdrawal(accreditation_date: string) {
    this.allianz_withdrawal_view_model.updateAccreditationDate(accreditation_date);
    const result = await this.$refs.allianz_withdrawal_confirm[0].withdrawal_confirm_view_model
      .confirmOperation(v4());
    this.allianz_withdrawal_view_model.operation_status = result ? 'success' : '';

    if (this.allianz_withdrawal_view_model.operation_status === 'success') {
      this.allianz_withdrawal_view_model.nextStep();
    }
  }
}

