import TYPES from '@/types';
import Vue from 'vue';

import { currencyFormat } from '@/vue-app/utils/currency';

import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';
import { SearchAllianzCustomerAvailableBalanceQuery } from '@/modules/my-investment/allianz/allianz-customer-available-balance/application/queries';
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';

// Domain
import { BankNameFormatter } from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/services';
import {
  AllianzAccountReportEntity,
} from '@/modules/my-investment/allianz/allianz-account-report/domain/entities/allianz-account-report-entity';
import {
  InvestmentProductFundTypeEntity,
} from '@/modules/flagship/catalogs/investment-product-fund-type/domain/entities/investment-product-fund-type-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { requiredRule } from '@/vue-app/utils/form-rules';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzWithdrawalInformationViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_QUERY)
  private readonly search_available_balance_query!: SearchAllianzCustomerAvailableBalanceQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  private readonly get_investment_product_fund_type_query!: GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly view: Vue;

  readonly i18n_namespace = 'components.allianz-dashboard.withdrawals.withdrawal';

  is_loading = false;

  is_valid_form = false;

  empty_source_account = {
    id: '',
    customer_id: '',
    allianz_account_id: '',
    transaction_id: '',
    request_number: '',
    product: '',
    policy_number: 0,
    client_code: '',
    client_name: '',
    activation_date: '',
    beginning_of_validity_date: '',
    agent_office: 0,
    agent_code: '',
    agent_name: '',
    promoter_code: '',
    promoter_name: '',
    grouper_code: '',
    grouper_name: '',
    advisor_code: '',
    advisor_name: '',
    item: '',
    found_code: '',
    found_name: '',
    amount_contributed: 0.00,
    withdrawal_amount: 0.00,
    charge_amount: 0.00,
    units: 0.00,
    units_value: 0.00,
    final_balance: 0.00,
    final_balance_mxn: 0.00,
    last_payment_date: '',
    updated_at: '',
    created_at: '',
  }

  inputs = {
    source_account: { ...this.empty_source_account },
    available_balance: '0.00',
    destination_account: '',
    amount_to_withdraw: '',
    investment_product_fund_type_id: '',
  };

  input_rules = {
    source_account: [
      requiredRule,
    ],
    destination_account: [
      requiredRule,
    ],
    available_balance: [
      requiredRule,
    ],
    amount_to_withdraw: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) >= 500
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$500.00 MXN' })
      ),
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) <= parseFloat(this.inputs.available_balance.replace(/[^0-9.-]/g, ''))
        || this.translate_errors('utils.form-rules.maximum_error', {
          value: currencyFormat(parseFloat(this.inputs.available_balance)),
        })
      ),
    ],
  };

  funds: Array<AllianzAccountReportEntity> = [];

  investment_product_fund_types: Array<InvestmentProductFundTypeEntity> = [];

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  reset = () => {
    this.inputs.amount_to_withdraw = '';
  }

  updateAvailableBalanceByFound = async (fund_code: string) => {
    const customer_available_balance = await this.search_available_balance_query
      .execute(fund_code);
    this.inputs.available_balance = customer_available_balance.available_balance.toFixed(2);
    const investment_product_fund_type = this.investment_product_fund_types.find(
      (fund_type) => fund_type.label === fund_code && fund_type.investment_product.name === 'sowos_wealth',
    );
    if (investment_product_fund_type) {
      this.inputs.investment_product_fund_type_id = investment_product_fund_type.id || '';
    }
    if (this.inputs.amount_to_withdraw !== '$0.00 MXN' && this.view.$refs.form_withdrawal) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.view.$refs.form_withdrawal.validate();
    }
    this.view.$emit('updateInvestmentProductFundTypeId', this.inputs.investment_product_fund_type_id);
  }

  formatSourceAccount = (value: AllianzAccountReportEntity) => (
    `${value.found_name}`
  );

  loadPosition = async () => {
    try {
      this.funds = await this.search_allianz_account_report_query.execute(this.customer_id);
      this.inputs.source_account = { ...this.empty_source_account };
      const found_founded = this.funds.find((fund) => fund.found_code === 'SWSRFP');
      if (found_founded) {
        await this.updateAvailableBalanceByFound(found_founded.found_code as string);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.inputs.source_account = found_founded;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_position'));
    }
  }

  loadInvestmentProductFundTypes = async () => {
    try {
      this.investment_product_fund_types = await this.get_investment_product_fund_type_query
        .execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_investment_product_fund_type'));
    }
  }

  loadAllianzAccount = async () => {
    try {
      const { bank, account } = await this.get_allianz_account_query.execute(
        { customer_id: this.customer_id },
      );
      const formatted_bank_name = BankNameFormatter.format(bank);
      this.inputs.destination_account = `${formatted_bank_name} **** ${account.substr(account.length - 4)}`;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_allianz_account'));
    }
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  nextStep = () => {
    this.view.$emit('updateInformation', this.inputs);
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestmentProductFundTypes();
    await this.loadAllianzAccount();
    await this.loadPosition();
    this.is_loading = false;
  }
}
