













































































































import { Component, Vue } from 'vue-property-decorator';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';

import AllianzWithdrawalInformationViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-information-view-model';

@Component({
  name: 'AllianzWithdrawalInformation',
  components: {
    CustomAutocomplete,
  },
})
export default class AllianzWithdrawalInformation extends Vue {
  withdrawal_information_view_model = Vue.observable(
    new AllianzWithdrawalInformationViewModel(this),
  );

  created() {
    this.withdrawal_information_view_model.initialize();
  }
}
