










































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import AllianzWithdrawalConfirmViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-confirm-view-model';

@Component({ name: 'AllianzWithdrawalConfirm' })
export default class AllianzWithdrawalConfirm extends Vue {
  withdrawal_confirm_view_model = Vue.observable(
    new AllianzWithdrawalConfirmViewModel(this),
  );

  created() {
    this.withdrawal_confirm_view_model.initialize();
  }
}

