import TYPES from '@/types';

// Application
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import {
  SearchTransactionsByRangeQuery,
} from '@/modules/my-investment/allianz/transaction/application/queries';
import SearchTransactionStatusQuery
  from '@/modules/my-investment/allianz/transaction-status/application/queries/search-transaction-status-query';
import SearchTransactionTypesQuery
  from '@/modules/my-investment/allianz/transaction-type/application/queries/search-transaction-types-query';

// Domain
import {
  SearchByCustomerDto,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  TransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/transaction-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AllianzWithdrawalViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  private readonly get_investment_product_fund_type_query!: GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_STATUS_QUERY)
  private readonly search_transaction_status_query!: SearchTransactionStatusQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_TYPES_QUERY)
  private readonly search_transaction_types_query!: SearchTransactionTypesQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_TRANSACTIONS_BY_RANGE_QUERY)
  private readonly search_allianz_transactions_query!: SearchTransactionsByRangeQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.withdrawals';

  is_open = false;

  operation_status = '';

  accreditation_date = '';

  sowos_moderate_investment_product_fund_type_id = '';

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  is_any_goal_active = false;

  is_moderate = false;

  current_step = 0;

  is_loading = false;

  pending_requests: TransactionEntity[] = []

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  steps = [
    {
      header: '',
      component: 'AllianzWithdrawalSchedule',
      ref: 'allianz_withdrawal_schedule',
      visible: false,
    },
    {
      header: '',
      component: 'AllianzWithdrawalPendingRequests',
      ref: 'allianz_withdrawal_pending_requests',
      visible: false,
    },
    {
      header: this.translate('steps_header.0'),
      component: 'AllianzWithdrawalInformation',
      ref: 'allianz_withdrawal_information',
      visible: true,
    },
    {
      header: this.translate('steps_header.1'),
      component: 'AllianzWithdrawalDistributeRetirement',
      ref: 'allianz_withdrawal_distribute_retirement',
      visible: true,
    },
    {
      header: this.translate('steps_header.2'),
      component: 'AllianzWithdrawalModerate',
      ref: 'allianz_withdrawal_moderate',
      visible: true,
    },
    {
      header: this.translate('steps_header.3'),
      component: 'AllianzWithdrawalConfirm',
      ref: 'allianz_withdrawal_confirm',
      visible: true,
    },
    {
      header: '',
      component: 'AllianzWithdrawalResult',
      ref: 'allianz_withdrawal_result',
      visible: false,
    },
  ];

  current_component = this.steps[this.current_step];

  headers: string[] = []

  get show_stepper_header() {
    return this.current_step > 1 && !this.operation_status;
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestmentProducts();
    await this.loadPendingRequests();
    await this.loadActiveGoals();
    await this.loadInvestmentProductFundTypes();
    this.is_loading = false;
  }

  nextStep = () => {
    if (this.current_step < this.steps.length) {
      this.current_step += 1;
      while (this.current_step < this.steps.length && !this.steps[this.current_step].visible
      && this.current_step < this.steps.length - 1) {
        this.current_step += 1;
      }
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      while (!this.steps[this.current_step].visible) {
        this.current_step -= 1;
      }
    }
  }

  updateAccreditationDate = (accreditation_date: string) => {
    this.accreditation_date = accreditation_date;
  }

  setInvestmentProductFundTypeId = (investment_product_fund_type_id: string) => {
    // eslint-disable-next-line max-len
    this.is_moderate = investment_product_fund_type_id === this.sowos_moderate_investment_product_fund_type_id;
    this.setCorrectMenuSteps();
  }

  setHeaders = () => {
    this.headers = this.steps.filter((step) => step.header !== '' && step.visible)
      .map((step) => step.header);
  }

  setCorrectMenuSteps = () => {
    if (this.is_any_goal_active && !this.is_moderate) {
      this.steps[3].visible = true;
      this.steps[4].visible = false;
    } else if (!this.is_any_goal_active && !this.is_moderate) {
      this.steps[3].visible = false;
      this.steps[4].visible = false;
    } else if (this.is_any_goal_active && this.is_moderate) {
      this.steps[3].visible = true;
      this.steps[4].visible = true;
    }
    this.setHeaders();
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadInvestmentProductFundTypes = async () => {
    try {
      const investment_product_fund_types = await this
        .get_investment_product_fund_type_query.execute();
      const moderate = investment_product_fund_types.find((item) => item.label === 'SWSMOD');
      this.sowos_moderate_investment_product_fund_type_id = moderate!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      const goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      this.is_any_goal_active = goals.length > 0;
      this.setCorrectMenuSteps();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  getNextWednesday = (today: Date) => {
    const day_of_week = today.getDay();
    const days_until_next_wednesday = (3 - day_of_week + 7) % 7;
    const next_wednesday = new Date(today);
    next_wednesday.setDate(today.getDate() + days_until_next_wednesday);
    return next_wednesday;
  }

  loadPendingRequests = async () => {
    try {
      const next_wednesday = this.getNextWednesday(new Date());
      const initial_date = new Date(next_wednesday.getTime());
      initial_date.setHours(0, 0, 0, 0);
      const final_date = new Date(next_wednesday.getTime());
      final_date.setHours(23, 59, 59, 999);

      const params = {
        initial_date: initial_date.toISOString().substr(0, 19),
        final_date: final_date.toISOString().substr(0, 19),
      };

      const requests = await this.search_allianz_transactions_query
        .execute(params);

      const withdrawal_pending_requests = requests
        .filter((request) => request
          .transaction_type.name === 'Withdrawal'
          && new Date(request.will_be_processed) < final_date);
      if (withdrawal_pending_requests.length === 0) {
        this.steps[1].visible = false;
      } else {
        this.steps[1].visible = true;
        this.pending_requests = withdrawal_pending_requests;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_pending_transactions'));
    }
  }

  reset = () => {
    this.is_open = false;
    this.operation_status = '';
    this.accreditation_date = '';
    this.current_step = 0;
  }
}
