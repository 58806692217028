import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetActiveGoalAmountByFundTypeQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-active-goal-amount-by-fund-type-query';

// Domain
import {
  InvestorGoalScheduledDistributionWealthWithdrawalDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/withdrawal/domain/dto/investor-goal-scheduled-distribution-wealth-withdrawal-dto';
import {
  ActiveGoalAmountByFundTypeEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/active-goal-amount-by-fund-type-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type LinkedGoal = {
  investor_goal_id: string;
  name: string;
  label: string;
  active_goal_amount: number;
  icon: string;
  assigned_amount: number;
  alt: string;
  assigned_amount_field: string;
  tooltip_text: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class AllianzWithdrawalDistributeRetirementViewModel {
  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_ACTIVE_GOAL_AMOUNT_BY_FUND_TYPE_QUERY)
  private readonly get_active_goal_amount_by_fund_type_query!: GetActiveGoalAmountByFundTypeQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  private readonly get_investment_product_fund_type_query!: GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.withdrawals.withdrawal-distribute-retirement';

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  private readonly customer_id = sessionStorage.getItem('user_id');

  linked_goals: Array<LinkedGoal> = [];

  retirement_amount = 0;

  step = 50;

  is_disabled = true;

  unassigned_balance = 0;

  total_balance = 0;

  is_some_goal_active = false;

  index_unassigned_amount = 0;

  payload_to_save_distribution: InvestorGoalScheduledDistributionWealthWithdrawalDto = {
    total_contribution: 0,
    unassigned_balance: 0,
    is_deposit: false,
    investor_goal_scheduled_distributions: [],
  };

  investment_product_fund_type_id = '';

  investment_product_id = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get retirement_amount_formatted() {
    return currencyFormat(this.retirement_amount);
  }

  getAmountFormatted = (amount: number) => currencyFormat(amount);

  getIconWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '30px' : '21px');

  createLinkedGoal = (
    active_goal_by_fund: ActiveGoalAmountByFundTypeEntity, is_moderate: boolean,
  ) => {
    let icon = 'icon-fund.svg';
    let icon_alt = 'alts.img_fund';

    if (active_goal_by_fund.default_icon_file && active_goal_by_fund.default_icon_file.icon_name) {
      icon = active_goal_by_fund.default_icon_file.icon_name;
      icon_alt = 'alts.custom';
    }
    return {
      investor_goal_id: active_goal_by_fund.investor_goal_id,
      // eslint-disable-next-line max-len
      name: active_goal_by_fund.investor_goal_name,
      // eslint-disable-next-line max-len
      label: active_goal_by_fund.investor_goal_name,
      active_goal_amount: Math.floor(parseFloat(active_goal_by_fund.active_goal_amount)) || 0,
      icon,
      assigned_amount: 0,
      alt: icon_alt,
      assigned_amount_field: '0.00',
      tooltip_text: is_moderate ? this.translate('tooltip_moderate') : '',
      is_moderate,
      icon_path: getGoalIconPath(
        active_goal_by_fund.custom_icon_file_id,
        icon,
        '',
      ),
    };
  }

  findInvestmentProductFundTypeById = async (investment_product_fund_type_id: string) => {
    const fund_types = await this.get_investment_product_fund_type_query
      .execute();

    const investment_product_fund_type = fund_types.find(
      (type) => type.id === investment_product_fund_type_id,
    );
    this.investment_product_id = investment_product_fund_type?.investment_product_id || '';
    return investment_product_fund_type;
  }

  validateIfProductTypeIsModerate = (investment_product_fund_name: string) => investment_product_fund_name === 'SWSMOD';

  loadActiveGoals = async (investment_product_fund_type_id: string) => {
    try {
      const investment_product_fund_type = await this.findInvestmentProductFundTypeById(
        investment_product_fund_type_id,
      );
      if (investment_product_fund_type) {
        const is_moderate = this.validateIfProductTypeIsModerate(
          investment_product_fund_type.label,
        );
        const active_goals_by_fund_type = await this.get_active_goal_amount_by_fund_type_query
          .execute(investment_product_fund_type_id);
        this.is_some_goal_active = active_goals_by_fund_type.length > 0;
        active_goals_by_fund_type.forEach((goal: ActiveGoalAmountByFundTypeEntity) => {
          const linked_goal = this.createLinkedGoal(goal, is_moderate);
          this.linked_goals.push(linked_goal);
        });
        this.index_unassigned_amount = this.linked_goals.length;
        if (this.is_some_goal_active && !is_moderate) {
          await this.loadCustomerInvestorGoalBalance();
          this.setUnassignedAmount();
        } else {
          this.unassigned_balance = 0;
          this.setUnassignedAmount();
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      // eslint-disable-next-line max-len
      const custom_investor_goal_balance = await this.get_customer_investor_goal_balance_query.execute(
        { investment_product_id: this.investment_product_id },
      );
      if (custom_investor_goal_balance.unassigned_balance) {
        this.unassigned_balance = custom_investor_goal_balance.unassigned_balance;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      }
    }
  }

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      active_goal_amount: Math.floor(this.unassigned_balance),
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: 0,
      alt: 'alts.custom',
      assigned_amount_field: '0.00',
      tooltip_text: '',
      is_moderate: false,
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
  }

  setRetirementAmount = async (amount: number, investment_product_fund_type_id: string) => {
    this.linked_goals = [];
    this.investment_product_fund_type_id = investment_product_fund_type_id;
    await this.loadActiveGoals(investment_product_fund_type_id);
    this.payload_to_save_distribution.investor_goal_scheduled_distributions.length = 0;
    this.retirement_amount = amount;
    this.is_disabled = true;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.linked_goals[index].active_goal_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index].assigned_amount
        .toFixed(2);
      this.validateTotalAmounts();
    }
  }

  decrementAmount = (index: number) => {
    if (this.linked_goals[index].assigned_amount > 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      if (this.linked_goals[index].assigned_amount < 0) {
        this.linked_goals[index].assigned_amount = 0;
      }
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index].assigned_amount
        .toFixed(2);
      this.validateTotalAmounts();
    }
  }

  validateTotalAmounts = () => {
    const amount_goals = this.linked_goals.map((obj) => (
      obj.assigned_amount
    ));
    const total_amount_goals = amount_goals.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    this.is_disabled = total_amount_goals !== this.retirement_amount;
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')).toFixed(2))
      : 0;
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.linked_goals[index].active_goal_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = parsed_assigned_amount_field.toFixed(2);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
      this.validateTotalAmounts();
    } else {
      this.linked_goals[index].assigned_amount_field = '0';
      this.linked_goals[index].assigned_amount = 0;
    }
  }

  nextStep = () => {
    let total_contribution = 0;
    this.linked_goals.forEach((goal) => {
      if (goal.investor_goal_id && goal.assigned_amount > 0) {
        this.payload_to_save_distribution.investor_goal_scheduled_distributions.push(
          {
            id: v4(),
            investor_goal_id: goal.investor_goal_id,
            investment_product_fund_type_id: this.investment_product_fund_type_id,
            amount_to_distribute: goal.assigned_amount,
          },
        );
      }
      total_contribution += goal.assigned_amount;
    });
    this.payload_to_save_distribution.unassigned_balance = this
      .linked_goals[this.index_unassigned_amount].assigned_amount;
    this.payload_to_save_distribution.total_contribution = total_contribution;
    this.view.$emit('showModalFlexibleDepositConfirmation', this.payload_to_save_distribution);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  getCustomizedModerateClass = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary py-5' : '');

  getCustomizedModerateClassTextField = (is_moderate: boolean) => ((is_moderate) ? 'white--text' : '');

  getCustomizedModerateBackgroundTextField = (is_moderate: boolean) => ((is_moderate) ? 'primary' : '');
}
